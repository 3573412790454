import dialogPolyfill from 'dialog-polyfill';
import 'material-design-lite';
import { getAssetList, createCdnJob, createPermissionsJob, deleteJob, showJobDetails, getJobs, showGroupSelect, getGroups } from './apiActions'
import { apiUrl, postData, getData, initSnackBar, showSnackbar, setDirModified } from './utils.js'

let serverConfig;
let appInitialized = false;
let idleTime = 0;
let idleTimer;

/**
 * On Load...
 */
document.addEventListener('DOMContentLoaded', () => {
    initSnackBar();
    initDialogs();

    getData(apiUrl('/api/config'))
        .then(jres => {
            serverConfig = jres;
            window.serverConfig = serverConfig;
            if (serverConfig.authEnabled) {
                getData(apiUrl('/api/auth/status'))
                    .then(jres => {
                        if (jres.authorized) {
                            getData(apiUrl('/api/auth/renew'));
                            loadApp();
                        } else {
                            showLogin();
                        }
                    }
                );
            } else {
                loadApp();
            }
        })
        .catch(() => {
            showSnackbar({message: `Unable to get configuration from server!`});
        }
    );
});

/**
 * Shows the login dialog
 */
function showLogin() {
    if (idleTimer) {
        clearInterval(idleTimer);
    }
    
    const dialog = document.getElementById('login-dialog');
    const loginFrm = document.getElementById('login-form');
    const userField = document.getElementById('username-s7');
    const passField = document.getElementById('password-s7');
    const scrim = document.querySelector('.blur-scrim');

    scrim.classList.remove('hide-scrim');

    [userField, passField].forEach(elem => {
        elem.closest('.mdl-textfield').classList.remove('is-invalid');
        elem.value = "";
    });

    dialog.showModal();

    loginFrm.addEventListener('submit', e => {
        e.preventDefault();
        const username = document.getElementById('username-s7').value;
        const password = document.getElementById('password-s7').value;

        postData(apiUrl('/api/auth'), {
            username,
            password
        }).then(jres => {
            if (jres.authorized) {
                userField.value = "";
                passField.value = "";
                loadApp();
                dialog.close();
            } else {
                showSnackbar({message: `The username/password you entered was incorrect!`});
            }
        })
    });
}

/**
 * Loads the main application
 */
function loadApp() {
    getJobs();
    getGroups();
    const scrim = document.querySelector('.blur-scrim');
    scrim.classList.add('hide-scrim');

    idleTimer = setInterval(timerIncrement, 120000); // two minutes

    if (!appInitialized) {
        appInitialized = true;
        const folderSearchForm = document.getElementById('folder-path-form');
        const s7Dir = document.getElementById('s7-dir');
        const createCdnJobButton = document.getElementById('create-cdn-job');
        const createPermissionsJobButton = document.getElementById('create-permissions-job');
        const s7JobCheck = document.getElementById('create-s7-job');

        folderSearchForm.addEventListener('submit', getAssetList);
        s7Dir.addEventListener('keydown', setDirModified);
        createCdnJobButton.addEventListener('click', createCdnJob);
        createPermissionsJobButton.addEventListener('click', showGroupSelect);
        s7JobCheck.addEventListener('change', s7JobHandler);

        document.body.addEventListener('click', delegateClick);

        // Reset idle timer
        ["mousemove", "keydown"].forEach(event => {
            document.addEventListener(event, () => {
                idleTime = 0;
            });
        });
    }
}

/**
 * Initializes <dialog> event listeners and data
 */
function initDialogs() {
    const dialogs = document.getElementsByTagName('dialog');
    for (let dialog of dialogs) {
        dialogPolyfill.registerDialog(dialog);
    }

    initMainDialog();
    initPermissionsDialog();
}

/**
 * Initializes the job details dialog
 */
function initMainDialog() {
    const mainDialog = document.getElementById('main-dialog');
    const mdClose = mainDialog.querySelector('.close');
    const mdRun  = mainDialog.querySelector('.run-job');

    if (mdClose) {
        mdClose.addEventListener('click', () => {
            mainDialog.close();
        });
    }

    if (mdRun) {
        mdRun.addEventListener('click', e => {
            const id = e.target.dataset.jobId;
            getData(apiUrl(`/api/jobs/${id}/run`)).finally(() => {
                mainDialog.close();
                getJobs();
                showSnackbar({message: `Running Job: ${id}`});
            });
        });
    }
}

/**
 * Initializes the "Select Group" permissions dialog
 */
function initPermissionsDialog() {
    const dialog = document.getElementById('permissions-dialog');
    const closeBtn = dialog.querySelector('.close');
    const submitBtn  = dialog.querySelector('.group-submit');

    if (closeBtn) {
        closeBtn.addEventListener('click', () => {
            dialog.close();
        });
    }

    if (submitBtn) {
        submitBtn.addEventListener('click', () => {
            createPermissionsJob(dialog);
        });
    }
}

/**
 * Shows/hides Scene7 Job options on checkbox click
 * @param {Event} e Event object
 */
function s7JobHandler(e) {
    const options = document.getElementById('s7-options');
    e.target.checked ? options.classList.remove('hidden') : options.classList.add('hidden');
}

/**
 * Event delegation function for transient elements
 * @param {Event} e Event object
 */
function delegateClick(e) {
    if (e.target.classList.contains('job-delete')) {
        deleteJob(e.target.dataset.jobId);
    }

    if (e.target.classList.contains('detailsLink')) {
        showJobDetails(e.target.dataset.jobId);
    }

    if (e.target.classList.contains('run-link-copy')) {
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
            if (result.state == "granted" || result.state == "prompt") {
                navigator.clipboard.writeText(e.target.dataset.url).then(function() {
                    showSnackbar({message: 'Job run URL copied to clipboard'});
                }, function() {
                    showSnackbar({message: 'ERROR: Could not copy URL to clipboard!'});
                });
            }
        });
    }
}

/**
 * Increments the inactivity timer and renews access token while active
 */
function timerIncrement() {
    idleTime += 2;
    console.log (`Idle: ${idleTime} min`);

    if (idleTime < 20) {
        getData(apiUrl('/api/auth/renew'))
    }

    getData(apiUrl('/api/auth/status')).then(jres => {
        if (!jres.authorized) {
            showLogin();
        }
    });
}
