import { getmdlSelect } from './lib/getmdl-select-esm';
// const mdl = require('getmdl-select/getmdl-select.min.js');
import { htmlToElement, apiUrl, postData, deleteData, initDialog, apiGetJob, getData, createStatusUpdater, showSnackbar, setDirModified, getJobNamesMap } from './utils';

/**
 * Calls the API to get the list of jobs (and renders on page)
 */
export function getJobs() {
    const jobTable = document.getElementById('jobs-table');
    
    let jobsUrl = apiUrl("/api/jobs");
    
    getData(jobsUrl)
    .then(jres => {
        const jobNames = getJobNamesMap();

        console.log(jres);
        jobTable.textContent = ''; // clear table

        if (jres.length) {
            const statusUpdates = [];

            jres.forEach(job => {
                const jobsHtml = htmlToElement(`
                <tr class="${job.id}">
                    <td class="mdl-data-table__cell--non-numeric">
                        <button class="mdl-button mdl-js-button mdl-button--icon job-delete job-icon default-job-icon material-icons" data-job-id="${job.id}">close</button>
                        <span class="spinner-container hidden job-progress">
                            <div class="mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active"></div>
                        </span>
                    </td>
                    <td class="mdl-data-table__cell--non-numeric">${job.id.split("-")[0]}</td>
                    <td class="mdl-data-table__cell--non-numeric">
                        <button class="mdl-button mdl-js-button mdl-button--icon run-link-copy material-icons" data-url="${apiUrl("/api/jobs/" + job.id + "/run")}">content_copy</button>
                    </td>
                    <td class="mdl-data-table__cell--non-numeric">
                        ${job.config.isPublish ? 'Publish & ' : ''}${jobNames[job.config.action]}${job.config.groupName ? ` (${job.config.groupName})` : ''} - ${job.config.path}
                    </td>
                    <td class="mdl-data-table__cell--non-numeric status">${job.status || ""}</td>
                    <td class="mdl-data-table__cell--non-numeric">
                        <button class="mdl-button mdl-js-button mdl-button--icon detailsLink material-icons" data-job-id="${job.id}">list_alt</button>
                    </td>
                    <td>${job.timesRun}</td>
                    <td class="mdl-data-table__cell--non-numeric">${job.lastRun === '0001-01-01T00:00:00Z' ? '' : new Date(job.lastRun).toLocaleString()}</td>
                    <td class="mdl-data-table__cell--non-numeric">${new Date(job.created).toLocaleString()}</td>
                </tr>
                `);

                // eslint-disable-next-line no-undef
                componentHandler.upgradeElement(jobsHtml.querySelector('.mdl-js-spinner'));
                jobTable.append(jobsHtml);
                const spinner = jobsHtml.querySelector('.spinner-container');
                const deleteBtn = jobsHtml.querySelector('.job-delete');

                if (job.status && job.status.includes("Running")) {
                    spinner.classList.remove('hidden');
                    deleteBtn.classList.add('hidden');
                    statusUpdates.push(createStatusUpdater(job.id, jobsHtml.querySelector('.status'), spinner, deleteBtn))
                }
            });

            if (statusUpdates.length) {
                Promise.allSettled(statusUpdates).finally(() => {
                    getJobs();
                });
            }
        }
    });
}

/**
 * Calls the API to get an asset list within a user-specified folder
 */
export function getAssetList(e) {
    e.preventDefault();
    const folderPath = document.getElementById('s7-dir').value;
    const recursive = document.getElementById('incl-subfolders').checked;
    const assetList = document.getElementById('asset-list');
    let assetListTxt = '';

    setDirModified();
    
    postData(apiUrl('/api/get/folder'), {
        path: folderPath,
        recursive: recursive
    }).then(res => {
        console.log(res);
        if (res.searchAssets && res.searchAssets.count) {
            res.searchAssets.files.forEach(file => {
                assetListTxt += `${file}\n`;
            });
            
            assetList.innerText = assetListTxt;
        } else if (res.searchAssets && res.searchAssets.count === 0) {
            assetList.innerText = `No assets found in the specified folder.`;
        } else {
            assetList.innerText = `Something went wrong!\n${JSON.stringify(res)}`;
        }
    });
}

/**
 * Calls the API to create a cdnCacheInvalidation job
 */
export function createCdnJob() {
    const { cacheClear } = window.serverConfig.actionNames;

    const s7Dir = document.getElementById('s7-dir');
    const folderPath = s7Dir.value;
    const pathModified = s7Dir.dataset.modified === "true";
    const recursive = document.getElementById('incl-subfolders').checked;
    const assetList = document.getElementById('asset-list').innerText.trim();
    const createS7Job = document.getElementById('create-s7-job').checked;
    const jobSchedule = createS7Job && document.getElementById('s7-job-time').value;
    const scheduleISO = jobSchedule && new Date(jobSchedule).toISOString();
    const isPublish = createS7Job && document.getElementById('s7-publish-assets').checked;

    let assetArray = [];
    assetList.split('\n').forEach(asset => {
        assetArray.push(asset);
    });

    if (!assetList) {
        showSnackbar({message: 'Please specify some files!'});
        return;
    }

    if (createS7Job && !jobSchedule) {
        showSnackbar({message: 'Please specify a time for the S7 job to run!'});
        return;
    }

    postData(apiUrl('/api/jobs'), {
        action: cacheClear,
        path: pathModified ? folderPath : "specific files",
        recursive: recursive,
        assets: assetArray,
        createS7Job: createS7Job,
        execTime: scheduleISO,
        baseUrl: apiUrl('/api/jobs/'),
        isPublish: isPublish
    }).then(res => {
        if (res.id) {
            getJobs();
        }
    });
}

/**
 * Shows the group selection dialog
 */
export function showGroupSelect() {
    const dialog = document.getElementById('permissions-dialog');

    dialog.showModal();
}

/**
 * Calls the API to create a permission-updating job
 */
export function createPermissionsJob(dialog) {
    const { updatePermissions } = window.serverConfig.actionNames;
    const folderPath = document.getElementById('s7-dir').value;
    const recursive = document.getElementById('incl-subfolders').checked;
    const createS7Job = document.getElementById('create-s7-job').checked;
    const jobSchedule = document.getElementById('s7-job-time').value;
    const scheduleISO = jobSchedule &&  new Date(jobSchedule).toISOString();
    const groupName = document.getElementById('group-field').value;
    const groupId = document.getElementById('group-id-field').value;

    if (createS7Job && !jobSchedule) {
        showSnackbar({message: 'Please specify a time for the S7 job to run!'});
        return;
    }

    if (!groupName || !groupId) {
        showSnackbar({message: 'Please select a group'});
        return;
    }

    dialog.close();

    postData(apiUrl('/api/jobs'), {
        action: updatePermissions,
        path: folderPath,
        recursive: recursive,
        createS7Job: createS7Job,
        execTime: scheduleISO,
        baseUrl: apiUrl('/api/jobs/'),
        isPublish: false, // explicitly false -- I don't ever see a case where we'd want to publish after releasing to signature _WITHOUT_ a cache clear
        group: groupId,
        groupName: groupName
    }).then(res => {
        if (res.id) {
            getJobs();
        }
    });
}

/**
 * Calls the API to delete the given jo
 * @param {} id - The ID of the job to delete
 */
export function deleteJob(id) {
    deleteData(apiUrl(`/api/jobs/${id}`)).then(res => {
        if (res.error) {
            showSnackbar({message: res.error});
        } else {
            showSnackbar({message: 'Job Deleted!'});
        }

        getJobs();
    })
}

/**
 * Calls the API to show details about a specific job
 * @param {} id - The ID of the job
 */
export function showJobDetails(id) {
    apiGetJob(id).then(jres => {
        const jobNames = getJobNamesMap();
        const { cacheClear } = window.serverConfig.actionNames;
        const { dialog, title, content, runBtn, assetList, outputText } = initDialog(true);
        const { id, config, timesRun, log, lastRun, created } = jres;
        const { action, assets, path, recursive, createS7Job, execTime, group, groupName } = config;
        
        title.innerText = 'Job Details';
        runBtn.dataset.jobId = id;
        content.innerHTML = `
            <p>${id}</p>
            <table class="mdl-data-table job-details-table">
                <tbody>
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Action</b></td>
                        <td class="mdl-data-table__cell--non-numeric">${jobNames[action]}</td>
                    </tr>
                    ${group && groupName ? `
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Group</b></td>
                        <td class="mdl-data-table__cell--non-numeric">${groupName} (${group})</td>
                    </tr>
                    ` : ''}
                    ${path ? `
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Path</b></td>
                        <td class="mdl-data-table__cell--non-numeric">${path}</td>
                    </tr>
                    ` : ''}
                    ${recursive ? `
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Include Subfolders</b></td>
                        <td class="mdl-data-table__cell--non-numeric">${recursive}</td>
                    </tr>
                    ` : ''}
                    ${createS7Job ? `
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Scene7 Job</b></td>
                        <td class="mdl-data-table__cell--non-numeric s7-job-schedule">
                            <span class="spinner-container">
                                <div class="mdl-spinner mdl-spinner--single-color mdl-js-spinner is-active"></div>
                            </span>
                        </td>
                    </tr>
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Scheduled Time</b></td>
                        <td class="mdl-data-table__cell--non-numeric s7-job-time">${new Date(execTime).toLocaleString()}</td>
                    </tr>
                    ` : ''}
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Times Run</b></td>
                        <td class="mdl-data-table__cell--non-numeric">${timesRun}</td>
                    </tr>
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Last Run</b></td>
                        <td class="mdl-data-table__cell--non-numeric">
                            ${lastRun !== "0001-01-01T00:00:00Z" ? new Date(lastRun).toLocaleString() : 'Never Run'}
                        </td>
                    </tr>
                    <tr>
                        <td class="mdl-data-table__cell--non-numeric"><b>Created</b></td>
                        <td class="mdl-data-table__cell--non-numeric">${new Date(created).toLocaleString()}</td>
                    </tr>
                </tbody>
            </table>
        `;
        
        assetList.value = '';
        if (assets && assets.length) {
            assetList.value = assets.join('\n');
        }

        outputText.value = log;

        if (createS7Job) {
            // eslint-disable-next-line no-undef
            componentHandler.upgradeElement(dialog.querySelector('.mdl-js-spinner'));
        }
        
        dialog.showModal();

        // Get job state from Scnee7
        if (createS7Job) {
            getData(apiUrl(`/api/get/jobschedule/${id}`)).then(jres => {
                const { jobs } = jres;
                const schedTd = document.querySelector('.s7-job-schedule');
                const spinner = schedTd.querySelector('.spinner-container');

                if (jobs && jobs.length === 1) {
                    const job = jobs[0];
                    spinner.classList.add('hidden');

                    if (id === job.name) {
                        schedTd.innerText = "Verified";
                        schedTd.classList.add('verified-text');
                    } else {
                        schedTd.innerText = "Job name mismatch!";
                        schedTd.classList.add('error-text');
                    }

                    schedTd.innerText += job.publish && !job.preservePublishState ? " - will publish" : " - will NOT publish";
                    

                    const jobTimeTd = document.querySelector('.s7-job-time');
                    jobTimeTd.innerText = new Date(job.scheduledTime).toLocaleString();
                    jobTimeTd.classList.add('verified-text');
                } else {
                    spinner.classList.add('hidden');
                    schedTd.innerText = "Could not verify (check Scene7)";
                    schedTd.classList.add('error-text');
                }
            });
        }
    });
}

export function getGroups() {
    const groupSelect = document.querySelector('.group-perms-select');
    let groupOptions = '';

    getData(apiUrl('/api/get/groups')).then(jres => {
        for (let group of jres.groups) {
            groupOptions += `<li class="mdl-menu__item" data-val="${group.id}">${group.name}</li>\n`;
        }

        groupSelect.innerHTML = groupOptions;

        getmdlSelect.init('.getmdl-select.groups')
    });
}