// eslint-disable-next-line no-undef
const isDev = process.env.NODE_ENV === "development";
let snackbar;

/**
 * Returns DOM Object of a given HTML string
 * @param {String} html 
 */
export function htmlToElement(html) {
    var template = document.createElement('template');
    html = html.trim(); // Never return a text node of whitespace as the result
    template.innerHTML = html;
    return template.content.firstChild;
}

/**
 * Returns a standardized API URL
 * @param {String} path 
 */
export function apiUrl(path) {
    let { hostname, port, protocol } = window.location;
    port = port ? ":" + port : port;
    
    if (isDev) {
        port = ":5000";
    }
    
    return protocol + '//' + hostname + port + path;
}

/**
 * Sends a GET request
 * @param {String} url - The URL to send the request to
 */
export async function getData(url = '') {
    const res = await fetch(url, {
        method: "GET",
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include'
    });

    return res.json();
}

/**
 * Sends a POST request
 * @param {String} url - The URL to send the request to
 * @param {Object} data - JS Object to be converted to JSON request body
 */
export async function postData(url = '', data = {}) {
    const res = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    
    console.log(res);
    return res.json();
}

/**
 * Sends a DELETE request
 * @param {String} url - The URL to send the request to
 * @param {Object} data - JS Object to be converted to JSON request body
 */
export async function deleteData(url = '', data = {}) {
    const res = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    });
    
    console.log(res);
    return res.json();
}

/**
 * Gets a specific job from the API
 * @param {String} id - The Job's ID 
 * @param {*} delay - Whether or not to delay this call (while looping)
 */
export function apiGetJob(id, delay = false) {
    return new Promise(resolve => {
        getData(apiUrl(`/api/jobs/${id}`))
            .then(jres => {
                if (delay)
                    setTimeout(() => { resolve(jres); }, 1500);
                else
                    resolve(jres);
            });
    });
}

/**
 * Sets up and returns handles to various dialog elements
 * @param { bool } isJob Whether or not this is a job details dialog
 */
export function initDialog(isJob = false) {
    const dialog = document.getElementById('main-dialog');
    const title = document.getElementById('dialog-title');
    const content = document.getElementById('replacable-content');
    const runBtn = dialog.querySelector('.run-job');
    const jobIO = document.getElementById('job-io-details');
    const assetList = document.getElementById('dialog-asset-list-text');
    const outputText = document.getElementById('dialog-job-output-text');


    if (isJob === true) {
        runBtn.classList.remove('hidden');
        jobIO.classList.remove('hidden');
    } else {
        runBtn.classList.add('hidden');
        jobIO.classList.add('hidden');
    }

    return { dialog, title, content, runBtn, assetList, outputText };
}

/**
 * Updates job entry with up-to-date status information while running
 * @param {*} id - The job's ID
 * @param {*} statusElem - The status element associated with this job
 * @param {*} spinner - The spinner element associated with this job
 * @param {*} deleteBtn - The delete button associated with this job
 */
export function createStatusUpdater(id, statusElem, spinner, deleteBtn) {
    return apiGetJob(id, true).then(jres => {
        const status = jres.status;
        statusElem.innerText = status;

        if (status === "Done") {
            spinner.classList.add('hidden');
            deleteBtn.classList.remove('hidden');
            return;
        } else {
            return createStatusUpdater(id, statusElem, spinner, deleteBtn);
        }
    });
}

/**
 * Initializes the common snackbar
 */
export function initSnackBar() {
    snackbar = document.getElementById('snackbar-container');
}

/**
 * Shows a snackbar
 * @param {Object} obj - Message object 
 */
export function showSnackbar(obj) {
    snackbar.MaterialSnackbar.showSnackbar(obj);
}

/**
 * Sets an attribute on the folder path text box when modified
 */
export function setDirModified() {
    const s7Dir = document.getElementById('s7-dir');
    s7Dir.dataset.modified = true;
}

/**
 * Maps job actions to user-readable job names
 */
export function getJobNamesMap() {
    const { cacheClear, updatePermissions } = window.serverConfig.actionNames;
    const jobNames = {}; 
    
    jobNames[cacheClear] = 'Invalidate CDN';
    jobNames[updatePermissions] = 'Update Permissions';

    return jobNames;
}